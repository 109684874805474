/* eslint-disable import/prefer-default-export */
export const optionsAnalytic = (t) => [
    {
        key: 'analytic',
        label: t('menu:analytic'),
        notInAcl: true,
        aclCode: 'header_shipment',
        children: [
            {
                aclCode: 'header_shipment',
                key: 'analytic_rfm',
                label: t('menu:analytic_campaign_performance'),
                url: '/analytic/campaign-performance',
                notInAcl: true,
            }, {
                aclCode: 'header_shipment',
                key: 'analytic_report_rfm',
                label: t('menu:analytic_report_rfm'),
                url: '/analytic/report-rfm',
                notInAcl: true,
            },
        ],
    },
];
