/* eslint-disable import/prefer-default-export */
export const optionsLoyalty = (t) => [
    {
        aclCode: 'header_shipment',
        key: 'loyalty',
        label: t('menu:Loyalty'),
        notInAcl: true,
        children: [
            {
                aclCode: 'oms_lite_sales_shipment',
                key: 'loyalty-customer',
                label: t('menu:Loyalty_Customers'),
                url: '/loyalty/customers',
                notInAcl: true,
            },
            {
                aclCode: 'shipment_pickup_dashboard',
                key: 'loyalty-transaction',
                label: t('menu:Loyalty_Transactions'),
                url: '/loyalty/transactions',
                notInAcl: true,
            },
            {
                aclCode: 'shipment_curbside_pickup',
                key: 'loyalty-configuration',
                label: t('menu:Loyalty_Configuration'),
                url: '/loyalty/configuration',
                notInAcl: true,
            },
        ],
    },
];
